/* components */
@import './Components/Home/HomeComponent.css';
@import './Components/About/AboutComponent.css';
@import './Components/Projects/ProjectsComponent.css';

.white {
    color: #f8f9fa;
}

.dark {
    color: darkslategray;
}

.bgWhite {
    background-color: #f8f9fa;
}

.centerSpan {
    margin: 0 auto;
    max-width: 60%;
}

.container {
    padding: 0;
    margin: 0;
    width: 100%;
    margin: 0 auto;
}

.row {
    justify-content: center;
    margin: 0;
}

.col {
    margin: 0;
    padding: 0;
}

.pdTop {
    padding-top: 25px;
}

.pdBot {
    padding-bottom: 25px;
}

.centerText {
    text-align: center;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    align-content: center;
}

.flexRow {
    display: flex;
    flex-direction: row;
    align-content: center;
}

.container-fluid {
    padding: 0;
}

.logo {
    height: 50px;
}

.max60 {
    max-width: 80%;
}

.max80 {
    max-width: 80%;
}

.max100 {
    max-width: 100%;
}

.imageContainer {
    justify-content: center;
    align-items: center;
}

.navbar-collapse {
    text-align: right;
}

.center {
    margin: 0 auto;
}

.widthPadding {
    margin-left: 20px;
    margin-right: 20px;
}

.icons {
    font-size: 50px;
}

.smallIcon {
    font-size: 30px;
}

a.nostyle:link {
    text-decoration: inherit;
    color: black;
    cursor: auto;
}

a.nostyle:visited {
    text-decoration: inherit;
    color: black;
    cursor: auto;
}

a.nostyle:hover {
    text-decoration: inherit;
    color: darkblue;
    cursor: pointer;
}

.projectHeader {
    line-height: 25px;
    font-size: 25px;
}

.cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.card {
    margin: 20px;
}

.backButton {
    float: left;
    color: black;
    width: 60%;
    font-size: 35px;
}

.backButton:link {
    text-decoration: inherit;
    /* color: darkblue; */
    cursor: auto;
}

.backButton:visited {
    text-decoration: inherit;
    color: black;
    cursor: auto;
}

.backButton:hover {
    text-decoration: inherit;
    color: darkblue;
    cursor: pointer;
}